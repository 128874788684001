import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Newsletter from "../components/newsletter"

class Contact extends React.Component {
  render() {
    const siteTitle = "Atul Prd"

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Newsletter"
          keywords={[`Blog`, `Product Design`, `Branding`, `UX Design`, `Product Designer`, `UX/UI`, `App Design`, `Brand Identity`]}
        />
        <h1 style={{ fontWeight: 800, marginTop: 20 }} >Ideas in your inbox</h1>
        <p>I write about topics that I'm interested in like product design, brand strategy, marketing, philosophy, creative entrepreneurship, mental models, & more. You can subscribe to my newsletter to receive these notes in your inbox.</p>
        < div className="BlogSub">
        <h3 style={{ fontWeight: 800, marginTop: 20 }} >Sign up for my newsletter <span role="img" aria-label>💌</span></h3>
        <p>My newsletter provides a behind-the-scenes look into what I'm working on and writing about. I frequently share some of my favorite articles I've read, as well as anything fascinating about design, business, & life. </p>
        <Newsletter/>
        </div>

      </Layout>
    )
  }
}

export default Contact
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    localSearchBlog {
      index
      store
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
